<template>
  <el-form ref="form" :model="user" :rules="rules" label-width="80px">
    <el-form-item :label="td('旧密码')" prop="oldPassword">
      <el-input v-model="user.oldPassword" :placeholder="td('请输入旧密码')" type="password" show-password/>
    </el-form-item>
    <el-form-item :label="td('新密码')" prop="newPassword">
      <el-input v-model="user.newPassword" :placeholder="td('请输入新密码')" type="password" show-password/>
    </el-form-item>
    <el-form-item :label="td('确认密码')" prop="confirmPassword">
      <el-input v-model="user.confirmPassword" :placeholder="td('请确认密码')" type="password" show-password/>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="mini" @click="submit">{{td("保存")}}</el-button>
      <el-button type="danger" size="mini" @click="close">{{td("关闭")}}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { updateUserPwd } from "@/api/system/user";

export default {
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.user.newPassword !== value) {
        callback(new Error(this.td("两次输入的密码不一致")));
      } else {
        callback();
      }
    };
    //验证密码的规则
    var checkEmail = (rule, value, cb) => {
      //验证密码的正则表达式（包含数字、字母大小写、特殊字符）
      const regEmail = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_.,/;:!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^.,/;:&*`~()-+=]+$)(?![0-9\\W_!@#.,/;:$%^&*`~()-+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\W_!@#$%.,/;:^&*`~()-+=]+$)(?![a-z0-9\\W_!@#$%.,/;:^&*`~()-+=]+$)(?![0-9A-Z\\W_!@#.,/;:$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$.,/;:%^&*`~()-+=]{8,30}$/;
      if (regEmail.test(value)) {
        //合法密码
        return cb();
      }
      cb(new Error( this.td("密码须包含大小写字母、数字以及特殊字符，且长度在8到30位")));
    };
    return {
      user: {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null
      },
      // 表单校验
      rules: {
        oldPassword: [
          { required: true, message: this.td("旧密码不能为空"), trigger: "blur" }
        ],
        newPassword: [
          { required: true, message: this.td("新密码不能为空"), trigger: "blur" },
          { validator: checkEmail, trigger: "blur" }
        ],
        confirmPassword: [
          { required: true, message: this.td("确认密码不能为空"), trigger: "blur" },
          { required: true, validator: equalToPassword, trigger: "blur" }
        ]
      }
    };
  }, 
  methods: {
    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          updateUserPwd(this.user.oldPassword, this.user.newPassword).then(
            res => {
               this.$message.success(this.td("修改成功"));
               this.user.oldPassword = null
               this.user.newPassword = null
               this.user.confirmPassword = null
            }
          );
        }
      });
    },
    close() {
      this.$router.push({ path: "/ERPP" });
    }
  }
};
</script>
